import React from "react";
import TabSelection from "./TabSelection/TabSelection";
const Carmine = React.lazy(() => import("./Jobs/Carmine"));
const Longevity = React.lazy(() => import("./Jobs/Longevity"));
const About = React.lazy(() => import("./Jobs/About"));
const Shc = React.lazy(() => import("./Jobs/Shc"));
const Map = React.lazy(() => import("./Jobs/Map"));
const Expenses = React.lazy(() => import("./Jobs/Expenses"));
const Meal = React.lazy(() => import("./Jobs/Meal"));

export default function Home() {
  return (
    <div>
      <div>
        {/* <ShowWindowDimensions /> */}
        {/* HOME PAGE */}

        <About />
        <Shc />
        <Longevity />
        <Carmine />
        <Meal />
        {/* <Maya /> */}
        {/* <Denisqo /> */}
        <Map />
        <Expenses />
        <TabSelection />
        {/* <TabSelectionPortfolio /> */}
      </div>
    </div>
  );
}
