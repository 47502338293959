import React, { useEffect, useState } from "react";
import "../../index.css";
import "../../Timeline.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const WorkExperience = () => {
  const [allPostsData] = useState([
    {
      date: "Aug 2017",
      img: "shc",
      name: "Suisse Health Company SA",
      title: "React Full-Stack Developer",
      description:
        "Creating full-stack using MERN Stack helping small and medium business to create an online presence",
      releaseDay: "01/08/2017",
      finishDay: "Present",
    },
    {
      date: "Jul 2017",
      img: "gulliver",
      name: "Gulliver srl",
      title: "JavaScript Developer",
      description:
        "Internship where I have used Gulliver's MDC platform to create mobile application using Javascript and JQuery",
      releaseDay: "20/04/2017",
      finishDay: "20/07/2017",
    },
    {
      date: "May 2016",
      img: "inps",
      name: "National Social Insurance Agency (INPS)",
      title: "Releasing SPID Credentials",
      description:
        "Internship where I had to help customers register and create an account to get SPID credential which allows people to access all the platforms created by the Italian government",
      releaseDay: "20/05/2016",
      finishDay: "20/06/2016",
    },
  ]);

  const animateFromTo = (elem, direction) => {
    const offset = 1000;
    let x = 0;
    let y = direction * offset;

    direction = direction | 1;

    if (elem.classList.contains("slide_from_left")) {
      x = -offset;
      y = 0;
    } else if (elem.classList.contains("slide_from_right")) {
      x = offset;
      y = 0;
    } else if (elem.classList.contains("slide_from_down")) {
      x = 0;
      y = offset;
    } else if (elem.classList.contains("slide_from_up")) {
      x = 0;
      y = -offset;
    }

    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 1.25,
        x: 0,
        y: 0,
        stagger: 0.33,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  };

  const hide = (elem) => {
    gsap.set(elem, { autoAlpha: 0 });
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".animate").forEach(function (elem) {
      hide(elem);

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFromTo(elem);
        },
        // onEnterBack: function () {
        //   animateFromTo(elem, -1);
        // },
        // onLeave: function () {
        //   hide(elem);
        // },
      });
    });
  });

  return (
    <div className="timeline">
      <ul>
        {allPostsData &&
          allPostsData.map((te, index) => (
            <li key={te.date}>
              <div className="content">
                <div
                  className={`imageWorkExperience slide_from_down animate ${te.img}`}
                ></div>

                <h5 className="slide_from_down animate">{te.name}</h5>
                <h5 className="slide_from_down animate">{te.releaseDate}</h5>
                <h3 className="slide_from_down animate">{te.title}</h3>
                <p className="slide_from_down animate">{te.description}</p>
              </div>
              <div className="time slide_from_down animate">
                <h4>{te.date}</h4>
              </div>
            </li>
          ))}
        <div style={{ clear: "both" }}></div>
      </ul>
    </div>
  );
};

export default WorkExperience;
