import React from "react";
import "./TabSelection/Languages/css/imgStyle.css";

const Skill = (props) => {
  return (
    <div className="flexColumn">
      <div class="flex-wrapper">
        <div class="single-chart">
          <svg viewBox="0 0 36 36" class="circular-chart blue">
            <path
              class="circle-bg"
              d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              class="circle"
              stroke-dasharray={`${props.percentage} , 100`}
              d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          </svg>

          <div className="skillLogoContainer">
            <div className={`img ${props.imgLogo}`}></div>
          </div>
        </div>
      </div>
      <div className="text">{props.name}</div>
    </div>
  );
};

export default Skill;
