import React, { useState } from "react";
import Skill from "../../Skill";
import "./css/imgStyle.css";

export default function Languages() {
  const [skill] = useState([
    {
      img: "react",
      name: "React",
      percentage: "90",
      category: "Front-End",
    },
    {
      img: "javascript",
      name: "JavaScript",
      percentage: "100",
      category: "Front-End",
    },
    {
      img: "html",
      name: "HTML5",
      percentage: "100",
      category: "Front-End",
    },
    {
      img: "css",
      name: "CSS3",
      percentage: "100",
      category: "Front-End",
    },
    {
      img: "node",
      name: "Node.js",
      percentage: "70",
      category: "Back-End",
    },
    {
      img: "express",
      name: "Express.js",
      percentage: "50",
      category: "Back-End",
    },
    {
      img: "php",
      name: "PHP",
      percentage: "50",
      category: "Back-End",
    },
    {
      img: "sql",
      name: "SQL",
      percentage: "40",
      category: "Back-End",
    },
    {
      img: "python",
      name: "Python",
      percentage: "30",
      category: "Back-End",
    },
  ]);
  return (
    <div className="skillsContainer">
      {skill.map((singleSkill) => (
        <Skill
          key={singleSkill.name}
          imgLogo={singleSkill.img}
          name={singleSkill.name}
          percentage={singleSkill.percentage}
        />
      ))}
    </div>
  );
}
