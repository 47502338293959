import React, { useEffect, useState } from "react";
import "../../index.css";
import "../../Timeline.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Education = () => {
  const [allPostsData] = useState([
    {
      date: "Oct 2019",
      img: "solent",
      name: "Solent University",
      title: "BSc Computer Science",
      description:
        "Web Technologies, Advanced Database, Java Object Oriented Programming, Backend Development, Cyber Security, Data Science",
      releaseDay: "09/2019",
      finishDay: "09/2022",
    },
    {
      date: "Sept 2014",
      img: "abba",
      name: "Istituto Tecnico Commerciale Statale Abba Ballini",
      title: "Sistemi Informativi Aziendali",
      description: "Italian A-Level equivalent in Bussiness Management and IT",
      releaseDay: "09/2014",
      finishDay: "07/2019",
    },
  ]);

  const animateFromTo = (elem, direction) => {
    const offset = 1000;
    let x = 0;
    let y = direction * offset;

    direction = direction | 1;

    if (elem.classList.contains("slide_from_left")) {
      x = -offset;
      y = 0;
    } else if (elem.classList.contains("slide_from_right")) {
      x = offset;
      y = 0;
    } else if (elem.classList.contains("slide_from_down")) {
      x = 0;
      y = offset;
    } else if (elem.classList.contains("slide_from_up")) {
      x = 0;
      y = -offset;
    }

    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 1.25,
        x: 0,
        y: 0,
        stagger: 0.33,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  };

  const hide = (elem) => {
    gsap.set(elem, { autoAlpha: 0 });
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".animate").forEach(function (elem) {
      hide(elem);

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFromTo(elem);
        },
        // onEnterBack: function () {
        //   animateFromTo(elem, -1);
        // },
        // onLeave: function () {
        //   hide(elem);
        // },
      });
    });
  });

  return (
    <div className="timeline">
      <ul>
        {allPostsData &&
          allPostsData.map((te, index) => (
            <li key={te.date}>
              <div className="content">
                <div
                  className={`imageWorkExperience slide_from_down animate ${te.img}`}
                ></div>

                <h5 className="slide_from_down animate uppercase">{te.name}</h5>
                <h5 className="slide_from_down animate">
                  {te.releaseDay} -{" "}
                  {`${te.finishDay}` === "" ? (
                    <span style={{ color: "var(--primary)" }}>PRESENT</span>
                  ) : (
                    te.finishDay
                  )}
                </h5>
                <h3 className="slide_from_down animate">{te.title}</h3>
                <p className="slide_from_down animate">{te.description}</p>
              </div>
              <div className="time slide_from_down animate">
                <h4>{te.date}</h4>
              </div>
            </li>
          ))}
        <div style={{ clear: "both" }}></div>
      </ul>
    </div>
  );
};

export default Education;
