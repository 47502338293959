import { createContext } from 'react';

const AnimationContext =  createContext();

export default AnimationContext;





