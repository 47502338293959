import React, { useEffect, useRef, useState, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Footer from "./components/Footer";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// import First from "./components/Jobs/First";
// import Second from "./components/Jobs/Second";
// import Third from "./components/Jobs/Third";
// import Fourth from "./components/Jobs/Fourth";
import { gsap } from "gsap/all";
import AnimationContext from "./contexts/animation-context";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#3c9ee5",
        // main: "#11cb5f",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#3c9ee5",
      },
    },
  });

  const [currentBg, setCurrentBg] = useState("#fff");
  let appRef = useRef(null);

  useEffect(() => {
    gsap.to(appRef.current, {
      duration: 1,
      background: currentBg,
    });
  }, [currentBg]);
  return (
    <Router>
      <Suspense fallback="Loading">
        <ThemeProvider theme={theme}>
          <div>
            {/* <Navbar /> */}
            <AnimationContext.Provider
              value={{ setCurrentBg }}
              style={{ pointerEvents: "none" }}
            >
              <div ref={appRef} className="pageContainer">
                <Switch>
                  <Route path="/" exact>
                    <Home />
                    {/* <First />
                  <Second />
                  <Third />
                  <Fourth /> */}
                  </Route>
                </Switch>
              </div>
              <Footer />
            </AnimationContext.Provider>
          </div>
        </ThemeProvider>
      </Suspense>
    </Router>
  );
}

export default App;
